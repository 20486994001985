



























































































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import DniField from "@/components/DniFieldBusiness.vue";
import {
  Configuration,
  SelectedCountry,
  RedCapital,
  TitleTemplateDashboard
} from "@/configuration";
import API from "@/api";
import { isRutValid } from "@/utils";
import { EventBus } from "@/main";
import { mapState } from "vuex";
import { DniTrim } from "@/utils";

@Component({
  computed: { ...mapState(["user", "setOpenMenuPanel"]),
    setOpenMenuPanel(){
        return this.$store.state.openMenuPanel;
      }
  },
  metaInfo: {
    title: "Crear empresa",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  data() {
    return {
      SelectedCountry,
      RedCapital,
      valid: true,
      loading: false,
      disabledName: true,
      rutValid: false,
      dialog: false,
      errorMessage: null,
      errorRut: null,
      errorPhone: null,
      errorDescription: null,
      errorRut404: null,
      rutInfo: null,
      rutInfo2: null,
      sale: [
        {
          id: 1,
          text: "Personas"
        },
        {
          id: 2,
          text: "Empresas"
        },
        {
          id: 3,
          text: "Ambas"
        }
      ],
      form: {
        name: "",
        dni: "",
        phone: null,
        address: "",
        debt: "",
        web: "",
        description: "",
        // radios: "siiPassword",
        // folder: null,
        siiPassword: "",
        businessName: "",
        order: "",
        sales: null,
        id: this.$store.state.user.id
      },
      rules: {
        name: [(v: any) => !!v || "Nombre es requerido"],
        dni: [
          // @ts-ignore
          (v: any) => !!v || "Rut es requerido"
        ],
        phone: [
          (v: any) => !/[^\d]/gim.test(v) || "Tiene que ser solo numeros"
        ],
        address: [(v: any) => !!v || "Dirección es requerido"],
        debt: [(v: any) => !/[^\d]/gim.test(v) || "Tiene que ser solo numeros"],
        description: [
          (v: any) =>
            v.length <= 255 || "Descripción no puede exceder los 255 caracteres"
        ],
        sales: [(v: any) => !!v || "Selecciona una opción"]
      }
    };
  },
  watch: {
    // "form.radios"(val: any) {
    //   if (val == "default") {
    //     this.$data.form.siiPassword = "sin-clave";
    //     this.$data.form.folder = null;
    //   }
    // },
    // "form.dni"() {
    //   if (SelectedCountry == "CL" || SelectedCountry == "PYME") {
    //     // @ts-ignore
    //     if (this.$refs.miniFormRut.validate()) {
    //       this.$data.errorRut = null;
    //       if (
    //         this.$data.form.dni.length == 11 ||
    //         this.$data.form.dni.length == 12
    //       ) {
    //         EventBus.$emit("dni-loading");
    //         Axios.get(
    //           `${Configuration.api.sii}${this.$data.form.dni.toUpperCase()}`
    //         )
    //           .then((res: any) => {
    //             if (this.$data.form.dni.slice(0, 2) < 40) {
    //               this.$data.disabledName = false;
    //               this.$data.form.name = res.data.razon_social;
    //             }
    //             this.$data.form.businessName = res.data.razon_social;
    //             this.$data.form.order = res.data.actividades[0].codigo;
    //             this.$data.rutInfo = res.data.razon_social;
    //             this.$data.rutInfo2 = res.data.actividades[0].codigo;
    //             EventBus.$emit("snack-success", "Rut validado");
    //             EventBus.$emit("dni-success");
    //           })
    //           .catch((error: any) => {
    //             this.$data.errorRut = null;
    //             this.$data.disabledName = true;
    //             this.$data.form.name = "";
    //             this.$data.form.businessName = null;
    //             this.$data.form.order = null;
    //             this.$data.rutInfo = null;
    //             this.$data.rutInfo2 = null;
    //             this.$data.errorRut = error.response.data.error.message;
    //             EventBus.$emit("snack-error", this.$data.errorRut);
    //             EventBus.$emit("dni-error");
    //           });
    //       }
    //     } else {
    //       this.$data.errorRut = null;
    //       this.$data.disabledName = true;
    //       this.$data.form.name = "";
    //       (this.$data.form.businessName = null), (this.$data.form.order = null);
    //       this.$data.rutInfo = null;
    //       this.$data.rutInfo2 = null;
    //       EventBus.$emit("dni-error");
    //     }
    //   } else {
    //     this.$data.form.businessName = "Default";
    //     this.$data.form.order = "Default";
    //     this.$data.form.siiPassword = "sin-clave";
    //   }
    // }
  },
  methods: {
    // getPersonaConGiro() {
    //   this.$data.loading = true;
    //   this.$data.form.dni = this.$store.state.user.rut;
    //   this.$data.form.name =
    //     this.$store.state.user.nombre + " " + this.$store.state.user.app_pat;
    //   if (isRutValid(this.$data.form.dni.toUpperCase())) {
    //     if (
    //       this.$data.form.dni.length == 9 ||
    //       this.$data.form.dni.length == 10
    //     ) {
    //       EventBus.$emit("dni-loading");
    //       Axios.get(
    //         `${Configuration.api.sii}${this.$data.form.dni.toUpperCase()}`
    //       )
    //         .then((res: any) => {
    //           this.$data.disabledName = false;
    //           this.$data.form.name = res.data.razon_social;
    //           this.$data.form.businessName = res.data.razon_social;
    //           this.$data.form.order = res.data.actividades[0].codigo;
    //           this.$data.rutInfo = res.data.razon_social;
    //           this.$data.rutInfo2 = res.data.actividades[0].codigo;
    //           EventBus.$emit("snack-success", "Rut válido");
    //           EventBus.$emit("dni-success");
    //           this.$data.loading = false;
    //         })
    //         .catch((error: any) => {
    //           this.$data.errorRut = null;
    //           this.$data.disabledName = true;
    //           this.$data.form.name = "";
    //           this.$data.form.businessName = null;
    //           this.$data.form.order = null;
    //           this.$data.rutInfo = null;
    //           this.$data.rutInfo2 = null;
    //           this.$data.errorRut = error.response.data.error.message;
    //           EventBus.$emit("snack-error", this.$data.errorRut);
    //           EventBus.$emit("dni-error");
    //           this.$data.loading = false;
    //         });
    //     }
    //   }
    // },
    validate() {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$data.valid = true;
        this.$data.errorMessage = null;

        let theTruth: any = {
          name: "nombre",
          dni: "rut",
          phone: "telefono",
          address: "direccion",
          debt: "deuda",
          web: "pagina_web",
          description: "descripcion",
          // siiPassword: "clavesii",
          businessName: "razon_social",
          // order: "giro",
          sales: "empresa_target_id",
          id: "user_id"
        };

        let payload: any = {};
        for (let key in this.$data.form) {
          if (theTruth[key] != undefined) {
            if (theTruth[key] == "rut") {
              payload[theTruth[key]] = DniTrim.string(this.$data.form[key]);
            }
            else if(theTruth[key] == "telefono") {
                // @ts-ignore
                if(RedCapital[SelectedCountry].PhoneCode == "+51"){
                  payload[theTruth[key]] = '51'+ this.$data.form.phone;
                }else{
                  payload[theTruth[key]] = this.$data.form[key];
                }
            } else {
              payload[theTruth[key]] = this.$data.form[key];
            }
          }
        }
        payload['razon_social'] = payload['nombre'];
        console.log(payload)
        API.createBusiness(payload)
          .then((res: any) => {
            this.$data.dialog = true;
            this.$store.commit("setBusiness", res.id);
            this.$store.commit("setEmpresasCount", +1);
            setTimeout(() => {
              this.$router.push({
                path: "/panel/mis-empresas"
              });
            }, Configuration.Redirection.timeout);
          })
          .catch((err: any) => {
            this.$data.errorMessage = err.data.message;
            EventBus.$emit("snack-error", "Revisa los errores");
          });
      }
    },
    fileUpload() {
      // @ts-ignore
      if (this.$refs.pdf.files.length > 0) {
        // @ts-ignore
        this.$data.form.folder = this.$refs.pdf.files[0];
      }
    }
  },
  components: {
    DniField
  }
})
export default class CreateBusiness extends Vue {}
